<template>
  <div class="My360ApplicationDetails">
    <!-- 360/KPI添加考核申请 -->
    <van-nav-bar
      :title="$t('jxStatisticalDataAdd.sdaTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="content">
      <van-form>
        <van-field
          :value="form.parentTitle"
          :label="$t('jxStatisticalDataAdd.sdaTxt2')"
          readonly
          input-align="right"
        />
        <van-field
          :value="userInfo.empcode"
          :label="$t('jxStatisticalDataAdd.sdaTxt3')"
          readonly
          input-align="right"
          v-show="form.is360 == 'false'"
        />
        <van-field
          :value="userInfo.empname"
          :label="$t('jxStatisticalDataAdd.sdaTxt4')"
          readonly
          input-align="right"
          v-show="form.is360 == 'false'"
        />
        <van-field
          :value="empInfo.deptname"
          :label="$t('jxStatisticalDataAdd.sdaTxt5')"
          readonly
          input-align="right"
          v-show="form.is360 == 'false'"
        />
        <van-field
          :value="empInfo.staname"
          :label="$t('jxStatisticalDataAdd.sdaTxt6')"
          readonly
          input-align="right"
          v-show="form.is360 == 'false'"
        />
        <!-- 直接评价人工号 -->
        <van-field
          label-class="vantitcls"
          :label="$t('jxStatisticalDataAdd.sdaTxt7')"
          readonly
          input-align="right"
          :value="form.itemname"
          @click="showEmpTreePop('项目名称')"
        />
        <van-cell
          class="sqnum_cell"
          :title="$t('jxStatisticalDataAdd.sdaTxt8')"
          title-class="vantitcls"
        >
          <template #default>
            <input
              style="
                width: 50px;
                text-align: center;
                border: 0;
                border-bottom: 1px solid #ccc;
              "
              type="text"
              v-model="form.itemval"
            />
          </template>
        </van-cell>

        <van-cell
          class="desc_cell"
          :value="$t('jxStatisticalDataAdd.sdaTxt9')"
        />
        <van-field
          class="desc_field"
          v-model="form.remark"
          rows="8"
          autosize
          type="textarea"
        />
      </van-form>
    </div>
    <div class="footer">
      <van-button type="info" size="small" @click="save">{{
        $t("jxStatisticalDataAdd.sdaTxt10")
      }}</van-button>
    </div>
    <PopTree
      ref="popTreeNode"
      :whichTree="'othertree'"
      :multiseltree="false"
      @treeSelectValEvent="treeSelectValEvent"
    ></PopTree>
  </div>
</template>

<script>
import PopTree from "@components/PopTree/index.vue";
import { Toast } from "vant";
import { addPubCiDetail, addPerCiDetail } from "@api/wxjx.js";

import { getEmpInfo } from "@api/wx";
export default {
  components: {
    PopTree,
  },
  data() {
    let mautoid = this.$route.query.mautoid;
    let detaId = this.$route.query.detaId;
    let length = this.$route.query.length;
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      mautoid,
      detaId,
      length,
      form: {
        parentTitle: this.$route.query.parentTitle,
        is360: this.$route.query.is360,
        itemval: "",
        itemname: this.$t("jxStatisticalDataAdd.sdaTxt11"),
        remark: "",
        itemid: 0,
      },
      showPicker: false,
      typeColumns: [],
      showTreePop: false, // 树弹窗显示
      empInfo: {
        deptname: "",
        staname: "",
      },
      ajaxModule: "",
      ajaxEditstate: 2,
      ajaxTreeName: "",
    };
  },
  created() {
    getEmpInfo({
      empcode: this.userInfo.empcode,
    }).then((res) => {
      this.empInfo = res.data[0];
    });
    this.form.itemval = "";
    this.form.itemname = this.$t("jxStatisticalDataAdd.sdaTxt11");
    this.form.remark = "";
    this.form.itemid = 0;
  },
  methods: {
    // 打开树
    showEmpTreePop(val, code) {
      if (this.form.is360 == false || this.form.is360 == "false") {
        this.$refs.popTreeNode.module = "514";
        this.$refs.popTreeNode.treename = "calitemtree:4";
        this.$refs.popTreeNode.editstate = 2;
      } else {
        this.$refs.popTreeNode.module = "506";
        this.$refs.popTreeNode.treename = "calitemtree:3";
        this.$refs.popTreeNode.editstate = 2;
      }
      this.$refs.popTreeNode.showEmpTreePop(val, code);
    },
    treeSelectValEvent(data) {
      this.form.itemname = data.keyname;
      this.form.itemid = data.autoid;
    },
    onClickLeft() {
      // this.$router.push('/jxMyAssTargetApply')
      this.$router.push({
        path: "/jxStatisticalDataList",
        query: {
          title: this.$route.query.parentTitle,
          is360: this.$route.query.is360,
          mautoid: this.mautoid,
        },
      });
    },
    // 添加按钮
    save() {
      // 没有ID的是新增
      if (this.form.is360 == true || this.form.is360 == "true") {
        // 360新增
        // console.log(this.form, "360新增");

        if (
          (this.form.itemval !== 0 || this.form.itemval !== "0") &&
          !this.form.itemval
        ) {
          Toast(this.$t("jxStatisticalDataAdd.sdaTxt12"));
          return;
        }
        if (!this.form.itemid) {
          Toast(this.$t("jxStatisticalDataAdd.sdaTxt13"));
          return;
        }
        addPubCiDetail({
          username: this.userInfo.username,
          khtimeid: this.mautoid || this.$route.query.mautoid,
          calitemid: this.form.itemid,
          itemvalue: this.form.itemval,
          remark: this.form.remark,
        }).then((res) => {
          if (res.iserror == 0) {
            if (res.data[0].info) {
              Toast(res.data[0].info);
            } else {
              Toast(this.$t("jxStatisticalDataAdd.sdaTxt14"));
              this.onClickLeft();
            }
          }
        });
      } else {
        if (
          (this.form.itemval !== 0 || this.form.itemval !== "0") &&
          !this.form.itemval
        ) {
          Toast(this.$t("jxStatisticalDataAdd.sdaTxt12"));
          return;
        }
        if (!this.form.itemid) {
          Toast(this.$t("jxStatisticalDataAdd.sdaTxt13"));
          return;
        }
        // KPI新增
        // console.log(this.form, "KPI新增");
        addPerCiDetail({
          username: this.userInfo.username,
          empid: this.userInfo.empid,
          khtimeid: this.mautoid || this.$route.query.mautoid,
          calitemid: this.form.itemid,
          itemvalue: this.form.itemval,
          remark: this.form.remark,
        }).then((res) => {
          if (res.iserror == 0) {
            if (res.data[0].info) {
              Toast(res.data[0].info);
            } else {
              Toast(this.$t("jxStatisticalDataAdd.sdaTxt14"));
              this.onClickLeft();
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.My360ApplicationDetails {
  height: 100vh;
  overflow: hidden;
  /deep/.vantitcls {
    color: red !important;
  }
  /deep/.vantitcls {
    span {
      color: red !important;
    }
  }
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .van-form {
      /deep/.van-field,
      .van-cell {
        margin: 30px 0;
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
          }
        }
      }
      .desc_cell {
        margin-bottom: 0;
        .van-cell__value {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
      }
      .desc_field {
        margin-top: 0;
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 60%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>